import React from "react"

import Layout from "../components/layout"

const Thanks = () => (
  <Layout>
    <div className="relative py-16 bg-white overflow-hidden">
      <div className="relative px-4 sm:px-6 lg:px-8">
        <div className="text-lg max-w-prose mx-auto">
          <h1>
            <span className="block text-base text-center text-blue-600 font-semibold tracking-wide uppercase">Success</span>
            <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">Thanks for getting in touch!</span>
          </h1>
        </div>
      </div>
    </div>
  </Layout>
)

export default Thanks
